import React from 'react';
import styles from 'src/scenarios';
import { createDialog } from 'components/base/dialog';
import { DragSource, DropTarget } from 'react-dnd';
import InputFieldDialog from './input_field_dialog';

class InputListItem extends React.Component {
  onFieldCodeChange = (e) => {
    e.preventDefault();
    var key = e.target.value;
    var r = { ...this.props.data };
    this.props.field_options.forEach((opt) => {
      if (opt.code == key) {
        r.field_type = opt.type;
        r.field_code = opt.code;
        r.field_label = opt.label;
        r.options = opt.options
          ? JSON.stringify(this.props.getOptionsByKintoneField(opt))
          : null;
        r.required = opt.required == 'true';
        r.allow_to_skip = false;
        r.default_value = null;
        r.use_default = 'unused';
        r.additional_explanation = opt.additional_explanation;
        r.lookup_app_id = opt.lookup ? opt.lookup.relatedApp.app : null;
        r.lookup_related_field = opt.lookup ? opt.lookup.relatedKeyField : null;
        r.lookup_order_by = opt.lookup ? opt.lookup.sort : null;
        r.lookup_search_by = opt.lookup ? opt.lookup.filterCond : null;
        r.lookup_display_field = opt.lookup ? opt.lookup.relatedKeyField : null;
        r.lookup_drill_down_app = null;
        r.lookup_drill_down_field = null;
        this.props.onChange(this.props.index, 'input_fields', r);
      }
    });
  };
  getFieldOption = () => {
    return this.props.field_options.find((option) => {
      return option.code === this.props.data.field_code;
    });
  };

  onChange = (props) => {
    var r = { ...this.props.data, ...props };
    this.props.onChange(this.props.index, 'input_fields', r);
  };

  onSettingsClick = (e) => {
    e.preventDefault();
    createDialog(InputFieldDialog, {
      data: this.props.data,
      field_option: this.getFieldOption(),
      getLookupDisplayFields: this.props.getLookupDisplayFields,
      onChange: this.onChange,
      lookupFieldMappings: this.props.lookupFieldMappings,
    });
  };

  onAdd = (e) => {
    e.preventDefault();
    this.props.onAdd(this.props.index, 'input_fields');
  };

  onRemove = (e) => {
    e.preventDefault();
    this.props.onRemove(this.props.index, 'input_fields');
  };

  render() {
    if (
      !this.props.connectDragPreview ||
      !this.props.connectDragSource ||
      !this.props.connectDropTarget
    )
      return;

    return this.props.connectDragPreview(
      this.props.connectDropTarget(
        <div className="row">
          <div className="col-sm-1">
            {this.props.connectDragSource(
              <button type="button" className="btn-sm handle text-center">
                <i className="material-icons">reorder</i>
              </button>,
              { dropEffect: 'move' },
            )}
          </div>
          <div className="col-sm-5">
            <select
              className="form-control"
              id={this.props.id}
              value={this.props.data.field_code || ''}
              onChange={this.onFieldCodeChange}
            >
              <option key="#blank" value="" />
              {this.props.field_options.map((o) => {
                return (
                  <option key={o.code} value={o.code}>
                    {o.label}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-sm-2">
            {this.props.data.field_code && (
              <span className="form-text">
                <a href="#!" onClick={this.onSettingsClick}>
                  入力項目の設定
                </a>
              </span>
            )}
          </div>
          <div className="col-sm-4 align-self-center">
            <span className={styles.buttons}>
              <a href="#!" onClick={this.onAdd}>
                <i className="material-icons mr-1">add_circle</i>
              </a>
              {this.props.removable && (
                <a href="#!" onClick={this.onRemove}>
                  <i className="material-icons mr-1">remove_circle</i>
                </a>
              )}
            </span>
          </div>
        </div>,
      ),
    );
  }
}

const itemSource = {
  beginDrag(props) {
    return props;
  },
};

function collectSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

const itemTarget = {
  hover(hoverProps, monitor, hoverComponent) {
    let dragProps = monitor.getItem();

    if (!hoverProps.data || !dragProps.data) return;
    if (hoverProps.data.id == dragProps.data.id) return;

    dragProps.onChangeOrder(dragProps.data, hoverProps.data, 'input_fields');
  },
};

function collectTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

var DecorateListItem = DragSource(
  'input_list_item',
  itemSource,
  collectSource,
)(InputListItem);
DecorateListItem = DropTarget(
  'input_list_item',
  itemTarget,
  collectTarget,
)(DecorateListItem);

export default DecorateListItem;
